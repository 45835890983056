import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import BlogDetailsContent from '../../components/blog/5-motive/BlogDetailsContent'

const BlogDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="5 motive pentru care afacerea ta are nevoie de software personalizat"
                homePageText="Blog"
                homePageUrl="/blog/"
                activePageText="5 motive pentru care afacerea ta are nevoie de software personalizat"
            /> 
            <BlogDetailsContent />
            <Footer />
        </Layout>
    )
}

export default BlogDetails;